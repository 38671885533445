import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
// import { AuthGuard, PermissionsGuard } from 'utils/routerGuards';
import RouterMiddlewares from '@/middlewares';
import { permissionsRedirection } from './utils/permissionsRedirection';
import { computeRedirectionWithParams } from './utils/computeRedirectionWithParams';

import qs from 'qs';
import axios from 'axios';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  parseQuery(query) {
    return qs.parse(query);
  },
  routes: [
    // Mayday-Web Embed - Used for login/logout redirection
    {
      path: '/web/embed/*',
      name: 'mayday-web-embed',
      component: () =>
        import(
          /* webpackChunkName: "web" */
          './layout/MaydayWeb/MaydayWebV2Redirection.vue'
        ),
      meta: {
        authorize: ['LoggedIn'],
      },
    },
    // Mayday-Web
    {
      path: '/web/:lang?',
      name: 'mayday-web',
      component: () =>
        import(
          /* webpackChunkName: "web" */
          './layout/MaydayWeb/MaydayWebV2Redirection'
        ),
      meta: {
        authorize: ['LoggedIn'],
      },
      children: [
        {
          path: 'knowledge/:knowledgeId',
          name: 'mayday-web-by-knowledge',
          meta: {
            authorize: ['LoggedIn'],
          },
          children: [
            {
              path: 'case/:caseId',
              name: 'mayday-web-by-case',
              meta: {
                authorize: ['LoggedIn'],
              },
            },
            {
              path: 'content/:contentId',
              name: 'mayday-web-by-content',
              meta: {
                authorize: ['LoggedIn'],
              },
              children: [
                {
                  path: 'step/:stepId',
                  name: 'mayday-web-by-step',
                  meta: {
                    authorize: ['LoggedIn'],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/web/:lang?/content/:contentId',
      name: 'mayday-web-by-content-shorted',
      component: () =>
        import(
          /* webpackChunkName: "web" */
          './layout/MaydayWeb/MaydayWebV2Redirection.vue'
        ),
      children: [
        {
          path: 'step/:stepId',
          name: 'mayday-web-by-step-shorted',
          meta: {
            authorize: ['LoggedIn'],
          },
        },
      ],
      meta: {
        authorize: ['LoggedIn'],
      },
    },
    {
      path: '/web/:lang?/case/:caseId',
      name: 'mayday-web-by-case-shorted',
      component: () =>
        import(
          /* webpackChunkName: "web" */
          './layout/MaydayWeb/MaydayWebV2Redirection.vue'
        ),
      meta: {
        authorize: ['LoggedIn'],
      },
    },
    // End Mayday-Web

    // Web-Parametric
    {
      path: '/web-parametric',
      name: 'web-home',
      component: () =>
        import(
          /* webpackChunkName: "web-parametric" */
          './layout/MaydayWeb/MaydayWebV2Redirection.vue'
        ),
      props: { isParametric: true },
      meta: {
        authorize: ['LoggedIn'],
      },
      children: [
        {
          path: '',
          name: 'web-filter',
          component: () =>
            import(
              /* webpackChunkName: "web-parametric" */
              './layout/MaydayWebParametric/MaydayWebParametricSearchFilter.vue'
            ),
          meta: {
            authorize: ['LoggedIn'],
          },
        },
        {
          path: 'filter',
          name: 'web-filter-results',
          component: () =>
            import(
              /* webpackChunkName: "web-parametric" */
              './views/KnowledgeEditor/KnowledgeEditorMain/SuperSearchResultsViewer.vue'
            ),
          meta: {
            authorize: ['LoggedIn'],
          },
        },
        {
          path: 'product/partial',
          name: 'web-product-partial',
          component: () =>
            import(
              /* webpackChunkName: "web-parametric" */
              './views/KnowledgeEditor/KnowledgeEditorMain/ProductViewer/MaydayWebProductViewer.vue'
            ),
          meta: {
            authorize: ['LoggedIn'],
          },
        },
        {
          path: 'product/:productId',
          name: 'web-product',
          component: () =>
            import(
              /* webpackChunkName: "web-parametric" */
              './views/KnowledgeEditor/KnowledgeEditorMain/ProductViewer/MaydayWebProductViewer.vue'
            ),
          meta: {
            authorize: ['LoggedIn'],
          },
        },
        {
          path: 'product/external_id/:externalId',
          name: 'web-product-external',
          component: () =>
            import(
              /* webpackChunkName: "web-parametric" */
              './views/KnowledgeEditor/KnowledgeEditorMain/ProductViewer/MaydayWebProductViewer.vue'
            ),
          meta: {
            authorize: ['LoggedIn'],
          },
        },
        {
          path: 'content/:contentId',
          name: 'web-content',
          component: () =>
            import(
              /* webpackChunkName: "web-parametric" */
              './views/KnowledgeEditor/ParametricContentBuilder/ParametricContentBuilder.vue'
            ),
          props: {
            editable: false,
          },
          meta: {
            authorize: ['LoggedIn'],
          },
          children: [
            {
              path: 'step/:stepId',
              name: 'web-content-step',
              component: () =>
                import(
                  /* webpackChunkName: "web-parametric" */
                  './views/KnowledgeEditor/ParametricContentBuilder/ParametricContentBuilder.vue'
                ),
              meta: {
                authorize: ['LoggedIn'],
              },
            },
          ],
        },
      ],
    },
    // End Web-Parametric

    {
      path: '/redirect',
      component: () => import('./components/redirectSso.vue'),
    },

    // Mayday-Admin
    {
      path: '/knowledge-editor',
      redirect: 'knowledge',
    },
    {
      path: '/',
      redirect: 'knowledge',
      component: () =>
        import(
          /* webpackChunkName: "new-dashboard" */
          './layout/DashboardLayout'
        ),
      children: [
        {
          path: '/ask',
          component: () => import('./views/Ask.vue'),
          meta: {
            authorize: ['LoggedIn'],
            companyPreferencesRequired: ['BRAIN_ENABLE_ASK_ADMIN_BETA'],
          },
        },
        {
          path: '/concepts',
          component: () => import('./views/Concepts.vue'),
          meta: {
            authorize: ['LoggedIn'],
            companyPreferencesRequired: ['BRAIN_ENABLE_CONCEPTS'],
          },
        },
        {
          path: '/dashboard',
          component: () =>
            import(
              /* webpackChunkName: "new-dashboard" */
              './views/NewDashboard/NewDashboard.vue'
            ),
          meta: {
            authorize: ['LoggedIn'],
            permissionsRequired: ['READ_ANALYTICS_DASHBOARDS'],
          },
          children: [
            { name: 'dashboard', path: '', redirect: 'analytics-contents' },
            {
              name: 'analytics-contents',
              path: 'analytics-contents',
              component: () =>
                import(
                  /* webpackChunkName: "new-dashboard" */
                  './views/NewDashboard/AnalyticsContents/AnalyticsContents.vue'
                ),
              meta: {
                tabName: 'contents',
                icon: 'books',
              },
            },
            {
              name: 'analytics-usages',
              path: 'analytics-usages',
              component: () =>
                import(
                  /* webpackChunkName: "new-dashboard" */
                  './views/NewDashboard/AnalyticsUsages/AnalyticsUsages.vue'
                ),
              meta: {
                tabName: 'usages',
                icon: 'chart-area',
              },
            },
            {
              name: 'analytics-ask',
              path: 'analytics-ask',
              component: () =>
                import(
                  /* webpackChunkName: "new-dashboard" */
                  './views/NewDashboard/AnalyticsAsk/AnalyticsAsk.vue'
                ),
              meta: {
                tabName: 'ask',
                icon: 'sparkles',
                companyPreferencesRequired: ['BRAIN_ENABLE_ASK'],
              },
            },
            {
              name: 'analytics-workflow',
              path: 'analytics-workflow',
              component: () =>
                import(
                  /* webpackChunkName: "new-dashboard" */
                  './views/NewDashboard/AnalyticsWorkflow/AnalyticsWorkflow.vue'
                ),
              meta: {
                tabName: 'workflow',
                icon: 'project-diagram',
                companyPreferencesRequired: ['WORKFLOW_ACTIVATED'],
              },
            },
            {
              name: 'analytics-workflow-session',
              path: 'analytics-workflow-session',
              component: () =>
                import(
                  /* webpackChunkName: "new-dashboard" */
                  './views/NewDashboard/AnalyticsWorkflowSession/AnalyticsWorkflowSession.vue'
                ),
              meta: {
                tabName: 'workflow-session',
                icon: 'radar',
                companyPreferencesRequired: ['WORKFLOW_ACTIVATED'],
              },
            },
            {
              name: 'analytics-download-table',
              path: 'analytics-download-table',
              component: () =>
                import(
                  './views/NewDashboard/AnalyticsDownload/AnalyticsDownload.vue'
                ),
              meta: {
                permissionsRequired: ['READ_ANALYTICS_EXPORTS'],
                tabName: 'download',
                icon: 'download',
              },
            },
          ],
        },
        {
          path: '/knowledge/:lang?',
          name: 'knowledge',
          component: () =>
            import(
              /* webpackChunkName: "knowledge" */
              './views/KnowledgeEditor/ParametricKnowledgeEditor.vue'
            ),
          meta: {
            authorize: ['LoggedIn'],
            permissionsRequired: ['EDIT_KNOWLEDGE_BASE'],
            adminRestriction: true,
            isLangRelated: true,
          },
          children: [
            {
              path: 'filter',
              name: 'knowledge-filter',
              component: () =>
                import(
                  /* webpackChunkName: "knowledge" */
                  './views/KnowledgeEditor/KnowledgeEditorMain/SuperSearchResultsViewer.vue'
                ),
              meta: {
                shouldNotOverrideEditingLang: true,
              },
            },
            {
              path: 'templates',
              name: 'knowledge-templates',
              component: () =>
                import(
                  /* webpackChunkName: "knowledge" */
                  './views/KnowledgeEditor/KnowledgeEditorMain/TemplatesViewer/TemplatesViewer.vue'
                ),
            },
            {
              path: 'templates/:templateId',
              name: 'knowledge-template',
              component: () =>
                import(
                  /* webpackChunkName: "knowledge" */
                  './views/KnowledgeEditor/KnowledgeEditorMain/TemplatesViewer/TemplateViewer.vue'
                ),
            },
            // TODO: delete
            // {
            //   path: 'archives',
            //   name: 'knowledge-archives',
            //   component: () =>
            //     import(
            //       /* webpackChunkName: "knowledge" */
            //       './views/KnowledgeEditor/KnowledgeEditorMain/ArchivesViewer/ArchivesViewer.vue'
            //     ),
            // },
            {
              path: 'archive/:archiveId',
              name: 'knowledge-archive',
              component: () =>
                import(
                  /* webpackChunkName: "demo" */
                  './views/KnowledgeEditor/ParametricContentBuilder/ParametricContentArchiveBuilder.vue'
                ),
            },
            {
              path: 'archive/case/:caseArchiveId',
              name: 'knowledge-case-archive',
              component: () =>
                import(
                  /* webpackChunkName: "demo" */
                  './views/KnowledgeEditor/KnowledgeEditorMain/ArchivesViewer/CaseArchiveViewer/CaseArchiveViewer.vue'
                ),
              children: [
                {
                  path: 'content/:archiveId',
                  name: 'knowledge-case-archive-content',
                  component: () =>
                    import(
                      /* webpackChunkName: "knowledge" */
                      './views/KnowledgeEditor/ParametricContentBuilder/ParametricContentArchiveBuilder.vue'
                    ),
                },
              ],
            },
            {
              path: 'case/:caseId',
              name: 'knowledge-case',
              component: () =>
                import(
                  /* webpackChunkName: "knowledge" */
                  './views/KnowledgeEditor/KnowledgeEditorMain/CaseViewer/CaseViewer.vue'
                ),
            },
            {
              path: 'product/:productId',
              name: 'knowledge-product',
              component: () =>
                import(
                  /* webpackChunkName: "knowledge" */
                  './views/KnowledgeEditor/KnowledgeEditorMain/ProductViewer/ProductViewer.vue'
                ),
            },
            {
              path: 'product/external_id/:externalId',
              name: 'knowledge-product-external',
              component: () =>
                import(
                  /* webpackChunkName: "knowledge" */
                  './views/KnowledgeEditor/KnowledgeEditorMain/ProductViewer/ProductViewer.vue'
                ),
            },
            {
              path: 'content/:contentId',
              name: 'knowledge-content',
              component: () =>
                import(
                  /* webpackChunkName: "knowledge" */
                  './views/KnowledgeEditor/ParametricContentBuilder/ParametricContentBuilder.vue'
                ),
              children: [
                {
                  path: 'step/:stepId',
                  name: 'knowledge-content-step',
                  component: () =>
                    import(
                      /* webpackChunkName: "knowledge" */
                      './views/KnowledgeEditor/ParametricContentBuilder/ParametricContentBuilder.vue'
                    ),
                },
              ],
            },
            {
              path: 'guide/:contentId',
              name: 'knowledge-guide',
              component: () =>
                import(
                  /* webpackChunkName: "knowledge" */
                  './views/KnowledgeEditor/ParametricContentBuilder/ParametricDiagnosticBuilder/ParametricDiagnosticBuilder.vue'
                ),
            },
            {
              path: '/knowledge/:lang?',
              name: 'knowledge-home',
              component: () =>
                import(
                  /* webpackChunkName: "knowledge" */
                  './views/KnowledgeEditor/ParametricHomeKnowledgeEditor/ParametricHomeKnowledgeEditor.vue'
                ),
            },
          ],
        },
        {
          path: '/notification-center',
          name: 'notification-center',
          component: () =>
            import(
              /* webpackChunkName: "notification" */ './views/NotificationCenter/NotificationCenter.vue'
            ),
          meta: {
            authorize: ['LoggedIn'],
            permissionsRequired: ['EDIT_AGENT_NOTIFICATION'],
            adminRestriction: true,
          },
        },
        {
          path: '/notification/feedbacks',
          name: 'feedbacks',
          component: () =>
            import(
              /* webpackChunkName: "notification" */ './views/Feedbacks.vue'
            ),
          meta: {
            authorize: ['LoggedIn'],
            permissionsRequired: ['EDIT_AGENT_NOTIFICATION'],
            adminRestriction: true,
          },
        },
        {
          path: '/tasks',
          name: 'tasks',
          redirect: 'tasks/board',
          component: () => import('./views/TaskView/TaskView.vue'),
          meta: {
            authorize: ['LoggedIn'],
            permissionsRequired: ['READ_TASK'],
          },
          children: [
            {
              name: 'board',
              path: 'board',
              component: () =>
                import('./views/TaskView/TaskBoard/TaskBoardWrapper.vue'),
            },
          ],
        },
        {
          path: 'private-settings/:knowledgeId',
          redirect: '/private-settings/:knowledgeId/configuration',
          name: 'private-settings',
          component: () =>
            import(
              /* webpackChunkName: "settings" */
              './views/Settings/PrivateKnowledge/PrivateKnowledge.vue'
            ),
          meta: {
            authorize: ['LoggedIn'],
            permissionsRequired: ['EDIT_COMPANY_USERS'],
            adminRestriction: true,
          },
          children: [
            {
              path: 'configuration',
              name: 'private-configuration',
              component: () =>
                import(
                  './views/Settings/PrivateKnowledge/PrivateKnowledge.vue'
                ),
            },
          ],
        },
        {
          path: '/public-settings/:knowledgeId',
          redirect: '/public-settings/:knowledgeId/appearances',
          name: 'public-settings',
          component: () =>
            import(
              /* webpackChunkName: "settings" */ './views/Settings/PublicKnowledge/PublicKnowledge.vue'
            ),
          meta: {
            authorize: ['LoggedIn'],
            permissionsRequired: ['EDIT_COMPANY_USERS'],
            adminRestriction: true,
          },
          children: [
            {
              path: 'appearances',
              name: 'public-appearances',
              component: () =>
                import(
                  './views/Settings/PublicKnowledge/PublicKnowledgeMenuItem.vue'
                ),
            },
            {
              path: 'configuration',
              name: 'public-configuration',
              component: () =>
                import(
                  './views/Settings/PublicKnowledge/PublicKnowledgeMenuItem.vue'
                ),
            },
            {
              path: 'marketplace',
              name: 'public-marketplace',
              component: () =>
                import(
                  './views/Settings/PublicKnowledge/PublicKnowledgeMenuItem.vue'
                ),
            },
          ],
        },
        {
          // TEMP - For QA Testing
          path: '/public-settings/:knowledgeId/embedded',
          name: 'embedded',
          component: () =>
            import('./views/Settings/PublicKnowledge/SelfcareEmbedded.vue'),
        },
        {
          // TEMP - For QA Testing
          path: '/public-settings/:knowledgeId/widget',
          name: 'widget',
          component: () =>
            import('./views/Settings/PublicKnowledge/SelfcareWidget.vue'),
        },
        {
          path: '/settings',
          redirect: 'settings/general',
          name: 'settings',
          component: () =>
            import(
              /* webpackChunkName: "settings" */
              './layout/SettingsLayout.vue'
            ),
          meta: {
            authorize: ['LoggedIn'],
            permissionsRequired: ['EDIT_COMPANY_USERS'],
            adminRestriction: true,
          },
          children: [
            {
              path: 'general',
              name: 'generalSettings',
              component: () =>
                import(
                  /* webpackChunkName: "settings" */ './views/Settings/SettingsView.vue'
                ),
            },
            {
              path: 'toasts',
              name: 'toastsSettings',
              component: () =>
                import(
                  /* webpackChunkName: "settings" */ './views/Settings/SettingsView.vue'
                ),
            },
            {
              path: 'integrations',
              name: 'integrationSettings',
              component: () =>
                import(
                  /* webpackChunkName: "settings" */
                  './views/Settings/SettingsView.vue'
                ),
            },
            {
              path: 'plugins',
              name: 'pluginsCatalog',
              component: () =>
                import(
                  /* webpackChunkName: "settings" */
                  './views/Settings/SettingsView.vue'
                ),
            },
            {
              path: 'users',
              name: 'usersSettings',
              component: () =>
                import(
                  /* webpackChunkName: "settings" */
                  './views/Settings/SettingsView.vue'
                ),
            },
            {
              path: 'permissions',
              name: 'permissionsSettings',
              component: () =>
                import(
                  /* webpackChunkName: "settings" */
                  './views/Settings/SettingsView.vue'
                ),
            },
            {
              path: 'ai-settings',
              name: 'aiSettings',
              component: () =>
                import(
                  /* webpackChunkName: "settings" */
                  './views/Settings/SettingsView.vue'
                ),
            },
            {
              path: 'search',
              name: 'searchSettings',
              component: () =>
                import(
                  /* webpackChunkName: "settings" */
                  './views/Settings/SettingsView.vue'
                ),
            },
            {
              path: 'knowledges',
              name: 'knowledgesSettings',
              component: () =>
                import(
                  /* webpackChunkName: "settings" */ './views/Settings/SettingsView.vue'
                ),
            },
            {
              path: 'academy',
              name: 'academySettings',
              component: () =>
                import(
                  /* webpackChunkName: "settings" */ './views/Settings/SettingsView.vue'
                ),
            },
            {
              path: 'security',
              name: 'securitySettings',
              component: () =>
                import(
                  /* webpackChunkName: "settings" */ './views/Settings/SettingsView.vue'
                ),
            },
            {
              path: 'company',
              name: 'companySettings',
              component: () =>
                import(
                  /* webpackChunkName: "settings" */ './views/Settings/SettingsView.vue'
                ),
            },
          ],
        },
        {
          path: 'settings/users/administrate',
          name: 'user-administrate',
          component: () =>
            import(
              /* webpackChunkName: "settings" */
              './views/Settings/PermissionSettings/UsersSettings/UsersAdministration/UsersAdministration.vue'
            ),
        },
        {
          path: 'settings/users/invitations',
          name: 'user-invitations',
          component: () =>
            import(
              /* webpackChunkName: "settings" */
              './views/Settings/PermissionSettings/UsersSettings/UsersInvitations/UsersInvitations.vue'
            ),
        },
        {
          path: 'settings/users/pending',
          name: 'pending-administrate',
          component: () =>
            import(
              './views/Settings/PermissionSettings/UsersSettings/UsersAdministration/UsersAdministration.vue'
            ),
          props: {
            pending: true,
          },
        },
        {
          path: 'settings/knowledges/new',
          name: 'new-knowledge',
          component: () =>
            import(
              /* webpackChunkName: "settings" */
              './views/Settings/GeneralSettings/KnowledgeManageSettings/NewKnowledge/NewKnowledge.vue'
            ),
        },
        {
          path: 'settings/synonyms',
          name: 'synonymsSettings',
          component: () =>
            import(
              /* webpackChunkName: "settings" */
              './views/Settings/SearchSettings/SynonymsSettings.vue'
            ),
        },
        {
          path: '/onboarding',
          name: 'onboarding',
          component: () =>
            import(/* webpackChunkName: "demo" */ './views/Onboarding.vue'),
          meta: {
            authorize: ['LoggedIn'],
            adminRestriction: true,
          },
        },
        {
          path: '/automations',
          name: 'automations',
          component: () => import('./views/Automations/Automations.vue'),
          meta: {
            authorize: ['LoggedIn'],
            permissionsRequired: ['EDIT_KNOWLEDGE_BASE'],
            adminRestriction: true,
          },
        },
        {
          path: '/old-automations',
          name: 'old-automations',
          component: () => import('./views/Automations.vue'),
          meta: {
            authorize: ['LoggedIn'],
            permissionsRequired: ['EDIT_KNOWLEDGE_BASE'],
            adminRestriction: true,
          },
        },
        {
          path: '/analytics-download',
          name: 'analytics-download',
          redirect: 'analytics-download/table',
          component: () =>
            import('./views/Analytics/AnalyticsDownload/AnalyticsDownload.vue'),
          meta: {
            authorize: ['LoggedIn'],
            permissionsRequired: ['READ_ANALYTICS_DASHBOARDS'],
            adminRestriction: true,
          },
          children: [
            {
              path: 'table',
              name: 'table',
              component: () =>
                import(
                  './views/Analytics/AnalyticsDownload/AnalyticsDownloadTable.vue'
                ),
            },
          ],
        },
      ],
    },
    {
      path: '/logout',
      name: 'logout',
      component: () =>
        import(/* webpackChunkName: "landing" */ './views/Login/Logout.vue'),
    },
    {
      path: '/',
      redirect: 'login',
      component: () =>
        import(
          /* webpackChunkName: "landing" */
          './layout/AuthLayout.vue'
        ),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () =>
            import(/* webpackChunkName: "landing" */ './views/Login/Login.vue'),
          beforeEnter: (to, from, next) => {
            const { token } = to.query;

            // IF LOGGED IN AND NOT COME FROM OBO TOKEN (RETOOL)
            if (!token && store.getters.loggedIn) return next({ path: '/' });

            if (store.getters.tooManyAttempts) {
              const remainingBlockedTimes =
                Date.now() - store.state.blockedLoginStartTime;
              const treeMinutes = 3 * 60 * 1000;
              if (remainingBlockedTimes < treeMinutes) {
                next({ path: '/reset' });
              }
              store.state.authAttempts = 0;
              store.state.blockedLoginStartTime = null;
            }
            next();
          },
        },
        {
          path: '/login-former',
          name: 'login-former',
          component: () =>
            import(/* webpackChunkName: "landing" */ './views/Login/Login.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters.tooManyAttempts) {
              const remainingBlockedTimes =
                Date.now() - store.state.blockedLoginStartTime;
              const treeMinutes = 3 * 60 * 1000;
              if (remainingBlockedTimes < treeMinutes) {
                next({ path: '/reset' });
              }
              store.state.authAttempts = 0;
              store.state.blockedLoginStartTime = null;
            }
            next();
          },
        },
        {
          path: '/register',
          name: 'register',
          component: () =>
            import(
              /* webpackChunkName: "landing" */ './views/Register/Register.vue'
            ),
          beforeEnter: (to, from, next) => {
            if (store.getters.loggedIn) return next({ path: '/' });
            next();
          },
        },
        {
          path: '/register-save',
          name: 'register-save',
          component: () =>
            import(
              /* webpackChunkName: "landing" */ './views/Register/Register.vue'
            ),
        },
        {
          path: '/user/invite',
          name: 'token-verifying',
          component: () =>
            import(
              /* webpackChunkName: "landing" */ './views/Register/UserInvitation.vue'
            ),
        },
        {
          path: '/reset',
          name: 'resetpassword',
          component: () =>
            import(
              /* webpackChunkName: "landing" */
              './views/ResetPassword/ResetPasswordStep1.vue'
            ),
        },
        {
          path: '/reset/:token',
          name: 'setnewpassword',
          component: () =>
            import(
              /* webpackChunkName: "landing" */ './views/ResetPassword/SetNewPassword.vue'
            ),
        },
      ],
    },
    {
      path: '/',
      redirect: 'home',
      component: () =>
        import(
          /* webpackChunkName: "demo" */
          './layout/HomeLayout.vue'
        ),
      children: [
        {
          path: '/home',
          name: 'home',
          component: () =>
            import(/* webpackChunkName: "demo" */ './views/UserLoggedIn.vue'),
          meta: {
            authorize: ['LoggedIn'],
          },
        },
      ],
    },
    {
      path: '/',
      redirect: 'validated',
      component: () =>
        import(
          /* webpackChunkName: "landing" */
          './layout/AuthLayout.vue'
        ),
      children: [
        {
          path: '/permissions/validated',
          name: 'validated',
          component: () =>
            import(/* webpackChunkName: "demo" */ './views/Validated.vue'),
        },
        {
          path: '/error',
          name: 'error',
          component: () => import('./views/Error.vue'),
        },
      ],
    },
    {
      path: '*',
      name: 'not-found',
      component: () =>
        import(/* webpackChunkName: "demo" */ './views/PageNotFound.vue'),
      meta: {
        authorize: ['LoggedIn'],
        adminRestriction: true,
      },
    },
    {
      path: '/invalid-token',
      name: 'invalid-token',
      component: () => import('./views/InvalidToken.vue'),
    },
  ],
});

// TEMP TODO REMOVE THIS, MOVE IT SERVER SIDE
const IP_WHITELIST = [
  // ankama
  '41.137.106.54',
  '197.230.122.116',
  '194.204.233.54',
  '102.50.246.164',
  '81.192.142.159',
  '81.192.111.112',
  '62.251.239.246',
  '52.28.19.177',
  '89.25.237.178',
  '204.16.184.7',
  '180.232.67.122',
  '111.125.86.210',
  '181.51.144.178',
  '181.59.36.159',
  '185.126.231.50',
  '185.31.151.186',
  '136.158.41.27',
  '51.254.67.33',
  '93.10.63.88',
  '176.170.240.101',
  '40.118.246.204',
  '13.86.253.112',
  '52.190.252.0',
  '41.137.220.80',
  '54.154.59.112',
  '190.157.8.101',
  '185.126.231.57',
  '20.74.112.201',
  // mayday
  '20.74.68.248',
];
const RESTRICTED_SUBDOMAINS = ['ankama', 'restricted-dev'];

router.beforeEach(async (to, from, next) => {
  // BAN IP FOR ANKAMA ACCOUNT
  const subdomain = window.location.host.split('.')[0];

  if (RESTRICTED_SUBDOMAINS.includes(subdomain)) {
    // BAN IP VARIABLES
    let isBan = window.localStorage.getItem('mayday_is_ban') === 'true';
    let banTtl = parseInt(window.localStorage.getItem('mayday_ban_ttl'), 10);
    // INIT STORAGE IF NOT THERE
    if (isNaN(banTtl)) {
      banTtl = Date.now();
    }

    const expired = banTtl < Date.now();
    if (
      isBan ||
      (!isBan && expired) ||
      window.localStorage.getItem('mayday_is_ban') === null
    ) {
      const {
        data: { data: ip },
      } = await axios.get('https://guess-ip.mayday.fr/api/guessip');

      isBan = !IP_WHITELIST.includes(ip);
      banTtl = Date.now() + 300000; // 5min
      window.localStorage.setItem('mayday_is_ban', isBan);
      window.localStorage.setItem('mayday_ban_ttl', banTtl);
    }

    if (isBan) {
      window.location.href = 'https://www.mayday.fr';
      return false;
    }
  }

  if (
    window.location.href.includes('http://') &&
    process.env.VUE_APP_FRONT_URL.includes('https://')
  ) {
    window.location.href = process.env.VUE_APP_FRONT_URL + to.path;
  }
  if (to.matched.some((record) => record.meta.authorize)) {
    if (
      store.getters.loggedIn // && Date.now() < store.state.accessExpirationDate
    ) {
      // Checking the page is restricted to Admins
      if (to.matched.some((record) => record.meta.permissionsRequired)) {
        const { userPermissions } = store.state;
        const { isParametric } = store.getters;
        let permissionsRequired = [];
        to.matched.forEach((record) => {
          const meta = record.meta;
          if (meta && meta.permissionsRequired) {
            permissionsRequired = permissionsRequired.concat(
              meta.permissionsRequired,
            );
          }
        });
        permissionsRequired = [...new Set(permissionsRequired)];
        // checking if the user has all the required permissions
        if (permissionsRequired.every((p) => userPermissions.includes(p))) {
          // do not trigger store middleware in case of hash changes
          if (to.path !== from.path) {
            RouterMiddlewares(to, store);
          }
          next();
        } else {
          const routeName = permissionsRedirection(
            userPermissions,
            isParametric,
            to,
          );

          if (routeName === 'academy') {
            window.location.href = '/academy';
            return;
          }

          if (routeName === 'archives') {
            window.location.href = '/archives';
            return;
          }

          if (routeName === 'notification-center') {
            window.location.href = '/notifications';
            return;
          }

          if (routeName == 'hub') {
            window.location.href = `/hub${
              to.query ? '?' + new URLSearchParams(to.query).toString() : ''
            }`;
            return;
          }

          const newToRoute = computeRedirectionWithParams(
            to,
            routeName,
            isParametric,
            store.getters.isAdmin,
          );

          next({
            ...newToRoute,
          });
        }
      } else if (
        to.matched.some((record) => record.meta.companyPreferencesRequired)
      ) {
        const { companyPreferences } = store.state;
        const { isParametric } = store.getters;
        let companyPreferencesRequired = [];
        to.matched.forEach((record) => {
          const meta = record.meta;
          if (meta && meta.companyPreferencesRequired) {
            companyPreferencesRequired = companyPreferencesRequired.concat(
              meta.companyPreferencesRequired,
            );
          }
        });
        companyPreferencesRequired = [...new Set(companyPreferencesRequired)];
        // checking if the user has all the required permissions
        if (
          companyPreferencesRequired.every(
            (p) =>
              companyPreferences.some((cp) => cp.type === p) &&
              companyPreferences.find((cp) => cp.type === p).value === '1',
          )
        ) {
          // do not trigger store middleware in case of hash changes
          if (to.path !== from.path) {
            RouterMiddlewares(to, store);
          }
          next();
        } else {
          const newToRoute = computeRedirectionWithParams(
            to,
            'not-found',
            isParametric,
          );
          next({
            ...newToRoute,
          });
        }
      }

      // No restriction on Role
      else {
        // do not trigger store middleware in case of hash changes
        if (to.path !== from.path) {
          RouterMiddlewares(to, store);
        }
        next();
      }
    }

    // Requires logged in so throw back to login
    else {
      const searchParams = new URLSearchParams(to.fullPath.split('?')['1']);
      const query = { redirect: to.fullPath };
      if (searchParams.get('token')) query.token = searchParams.get('token');
      next({
        name: 'login',
        query,
      });
    }
  } else {
    // do not trigger store middleware in case of hash changes
    if (to.path !== from.path) {
      RouterMiddlewares(to, store);
    }
    next();
  }
});

export default router;
